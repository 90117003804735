import { ContentItem, Level1Item, Level2Item, PropertyType, ContentItemProperty } from './types';
import defaultTemplate from './defaultTemplate.json';
import subsectionTemplates from './subsectionTemplates.json';

interface SubsectionTemplate {
  id: string;
  name: string;
  description: string;
  properties: {
    hasChildren: boolean;
    childLabels: {
      singular: string;
      plural: string;
    };
    childProperties: {
      key: string;
      type: PropertyType;
      label: string;
      required: boolean;
      name: string;
    }[];
  };
}

const createUniqueTemplateId = (parentId: string, templateId: string): string => {
  return `${parentId}-${templateId}`;
};

export const templateService = {
  getRootTemplate(): Level1Item[] {
    // Custom sort function to put Company first, then alphabetically
    const sortTemplates = (a: Level1Item, b: Level1Item) => {
      if (a.id === 'company') return -1;
      if (b.id === 'company') return 1;
      return (a.name || '').localeCompare(b.name || '');
    };

    return defaultTemplate
      .map(section => ({
        id: section.id,
        name: section.name,
        description: section.description,
        template_id: section.id,
        parent_id: null,
        template_parent_id: null,
        content: '',
        properties: {
          hasChildren: true as const,
          childLabels: {
            singular: 'Category',
            plural: 'Categories'
          }
        }
      } as Level1Item))
      .sort(sortTemplates);
  },

  getSubsectionTemplate(templateId: string): SubsectionTemplate | null {
    const template = subsectionTemplates.find(t => t.id === templateId);
    if (!template) return null;
    
    const childProperties = template.properties.childProperties.map(prop => ({
      ...prop,
      type: prop.type as PropertyType
    }));
    
    return {
      id: template.id,
      name: template.name,
      description: template.description,
      properties: {
        ...template.properties,
        childProperties
      }
    };
  },

  createSubsection(template: SubsectionTemplate, parentId: string): Level2Item {
    return {
      id: `${parentId}-${template.id}`,
      name: template.name,
      parent_id: parentId,
      template_id: template.id,
      template_parent_id: null,
      content: '',
      description: template.description,
      properties: {
        hasChildren: true,
        childLabels: template.properties.childLabels,
        childProperties: template.properties.childProperties as ContentItemProperty[]
      }
    };
  },

  getChildLabels(templateId: string) {
    const template = this.getSubsectionTemplate(templateId);
    return template?.properties.childLabels || {
      singular: 'Document',
      plural: 'Documents'
    };
  }
}; 