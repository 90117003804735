export interface BaseContentItem {
  id: string;
  name: string;
  parent_id: string | null;
  template_parent_id: string | null;
  template_id?: string;
  content: string;
  description?: string;
  status_id?: number;
  company_id?: string;
  properties: {
    hasChildren?: boolean;
    childLabels?: {
      singular: string;
      plural: string;
    };
    childProperties?: ContentItemProperty[];
  };
}

export interface Level1Item extends BaseContentItem {
  parent_id: null;
  template_parent_id: null;
  template_id: string;
  properties: {
    hasChildren: true;
    childLabels: {
      singular: string;
      plural: string;
    };
    childProperties?: ContentItemProperty[];
  };
}

export interface Level2Item extends BaseContentItem {
  parent_id: string;
  template_parent_id: null;
  template_id: string;
}

export interface Level3Item extends BaseContentItem {
  parent_id: null;
  template_parent_id: string;
}

export interface LevelNItem extends BaseContentItem {
  parent_id: string;
  template_parent_id: string;
}

export type ContentItem = Level1Item | Level2Item | Level3Item | LevelNItem;

// Type guards
export const isLevel1Item = (item: ContentItem): item is Level1Item => {
  return item.parent_id === null && item.template_parent_id === null;
};

export const isLevel2Item = (item: ContentItem): item is Level2Item => {
  return item.parent_id !== null && item.template_parent_id === null && !!item.template_id;
};

export const isLevel3Item = (item: ContentItem): item is Level3Item => {
  return item.parent_id === null && item.template_parent_id !== null;
};

export const isLevelNItem = (item: ContentItem): item is LevelNItem => {
  return item.parent_id !== null && item.template_parent_id !== null;
};

// Property types
export type PropertyType = 'text' | 'longtext' | 'number' | 'date' | 'boolean' | 'select';

export interface ContentItemProperty {
  key: string;
  type: PropertyType;
  label: string;
  required: boolean;
  name: string;
}

// DocBuilder Props
export interface DocBuilderProps {
  items: ContentItem[];
  onItemAdd: (item: ContentItem) => Promise<ContentItem>;
  onItemUpdate: (item: ContentItem) => Promise<void>;
  onGeneratePDF?: (itemId: string) => Promise<void>;
  onPublish?: (itemId: string) => Promise<void>;
  isLoading?: boolean;
  onItemClick: (item: ContentItem) => void;
  initialSelectedId?: string | null;
  onLoadChildren?: (parentId: string) => Promise<void>;
} 