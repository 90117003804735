import { Container, Paper, Title, Text, Stack } from '@mantine/core';

export function Library() {
  return (
    <Container size="xl">
      <Paper shadow="sm" p="xl" withBorder>
        <Stack align="center" spacing="md">
          <Title order={2}>Document Library</Title>
          <Text size="lg" color="dimmed">
            Coming Soon
          </Text>
          <Text align="center" color="dimmed">
            The document library feature is currently under development. 
            Check back soon for access to your organization's document repository.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
} 