import React from "react";
import { useGetIdentity } from "@refinedev/core";
import { DeepChat as DeepChatT } from "deep-chat";
import { DeepChat } from "deep-chat-react";
import { IIdentity } from "../../interfaces";

export const AppsChat = () => {
  const { data: identity, isLoading, error } = useGetIdentity<IIdentity>();

  console.log("Identity data:", identity);
  console.log("Identity loading:", isLoading);
  console.log("Identity error:", error);
  console.log("Session:", identity?.session);
  console.log("Access token:", identity?.session?.access_token);

  const token = identity?.session?.access_token;

  const chatElementRef = React.useRef<DeepChatT>(null);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "calc(100vw - 350px)",
        height: "calc(100vh - 120px)",
      }}
    >
      {identity && (
        <DeepChat
          style={{
            borderRadius: "10px",
            maxWidth: "1200px",
            width: "100%",
            height: "calc(100vh - 180px)",
            paddingTop: "10px",
            margin: "0 auto",
          }}
          ref={chatElementRef}
          demo={true}
          initialMessages={[
            {
              text: "Hi Matt.",
              role: "assistant",
            },
          ]}
          request={{
            url:
              "https://dvyrjvdxxmkphcuuzrev.supabase.co/functions/v1/zenbot-api/web",
            "headers": { "Authorization": `Bearer ${token}` },
            additionalBodyProps: { "messageId": "123456789" },
          }}
          requestBodyLimits={{
            "totalMessagesMaxCharLength": 200,
            "maxMessages": 5,
          }}
          messageStyles={{ default: { ai: { bubble: { maxWidth: "90%" } } } }}
        />
      )}
    </div>
  );
};
