import { ContentItem, isLevel1Item, isLevel2Item, isLevel3Item } from '../types';
import { staticTemplateService } from './staticTemplateService';
import { dynamicItemService } from './dynamicItemService';

export const documentService = {
  getChildren(parentId: string | null, items: ContentItem[]): ContentItem[] {
    console.log('\n=== documentService.getChildren ===');
    console.log('parentId:', parentId);

    if (parentId === null) {
      console.log('At root level');
      return staticTemplateService.getRootItems();
    }

    const parent = items.find(i => i.id === parentId);
    if (!parent) {
      console.log('No parent found');
      return [];
    }

    // Log parent details for debugging
    console.log('Parent details:', {
      id: parent.id,
      name: parent.name,
      isLevel1: isLevel1Item(parent),
      isLevel2: isLevel2Item(parent),
      isLevel3: isLevel3Item(parent)
    });

    // Level 1 items (like Company) -> return their static subsections
    if (isLevel1Item(parent)) {
      console.log('Getting static subsections for Level 1 item');
      return staticTemplateService.getStaticSubsections(parent.id);
    }

    // Level 2 items (like Assets) -> return items with matching template_parent_id
    if (isLevel2Item(parent)) {
      console.log('Looking for Level 3 items with template_parent_id:', parent.id);
      const children = items.filter(item => 
        isLevel3Item(item) && item.template_parent_id === parent.id
      );
      console.log('Found Level 3 children:', children);
      return children;
    }

    // Level 3 or higher items -> return direct children
    console.log('Getting dynamic children for:', parent.id);
    const dynamicChildren = items.filter(item => 
      !isLevel1Item(item) && 
      !isLevel2Item(item) && 
      item.parent_id === parent.id
    );
    console.log('Found dynamic children:', dynamicChildren);
    return dynamicChildren;
  },

  createNewItem(parent: ContentItem, name: string): Partial<ContentItem> {
    // Creating child under Level 2 item (template)
    if (isLevel2Item(parent)) {
      return {
        id: crypto.randomUUID(),
        name,
        content: '',
        description: '',
        properties: {},
        parent_id: null,
        template_parent_id: parent.id,
        template_id: parent.template_id
      };
    }

    // Creating child under Level 3 or higher item
    return {
      id: crypto.randomUUID(),
      name,
      content: '',
      description: '',
      properties: {},
      parent_id: parent.id,
      template_parent_id: parent.template_parent_id,
      template_id: parent.template_id
    };
  }
}; 