import { ContentItem, Level1Item, Level2Item, PropertyType, ContentItemProperty } from '../types';
import defaultTemplate from '../defaultTemplate.json';
import subsectionTemplates from '../subsectionTemplates.json';

export const staticTemplateService = {
  getRootItems(): Level1Item[] {
    return defaultTemplate.map(section => ({
      ...section,
      name: section.name,
      content: '',
      properties: {
        hasChildren: true,
        childLabels: {
          singular: 'Category',
          plural: 'Categories'
        }
      }
    })) as Level1Item[];
  },

  getStaticSubsections(parentId: string): Level2Item[] {
    const subsectionTypes = subsectionTemplates;
    console.log('Creating static subsections for parent:', parentId);
    
    return subsectionTypes
      .map(type => ({
        id: `${parentId}-${type.id}`,
        name: type.name,
        parent_id: parentId,
        template_id: type.id,
        template_parent_id: null,
        content: '',
        description: type.description,
        properties: {
          hasChildren: true,
          childLabels: type.properties.childLabels,
          childProperties: (type.properties.childProperties || []).map(prop => ({
            ...prop,
            type: prop.type as PropertyType
          })) as ContentItemProperty[],
        }
      } as Level2Item));
  },

  isStaticItem(item: ContentItem | Level1Item): boolean {
    const result = !item.template_parent_id;
    console.log('isStaticItem check:', {
      item: {
        id: item.id,
        name: item.name,
        template_parent_id: item.template_parent_id
      },
      result
    });
    return result;
  },

  getTemplateParentId(parentId: string, templateId: string): string {
    return `${parentId}-${templateId}`;
  }
}; 