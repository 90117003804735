import React from 'react';
import { useNavigate } from "react-router-dom";

import { Refine, Authenticated } from "@refinedev/core";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import {ErrorComponent } from "@refinedev/mantine";
import { supabaseClient } from "./utility";

import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import routerBindings from "@refinedev/react-router-v6";  
import { MantineProvider, Global, AppShell } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import authProvider from "./authProvider";
import { TitleProvider} from "./contexts/TitleProviderContext"; 

import "./App.css";

import { DoubleNavbar } from './components/custom/AppShell/_doubleNavbar';
import { ContentHeader } from './components/custom/AppShell/_contentHeader';
import AnimatedBackground from './components/custom/AppShell/_animatedBackground';
import { AuthenticationForm } from './components/auth/loginRegister';
import { PasswordResetForm } from './components/auth/passwordReset';
import ScrollAreaStyle from './components/custom/AppShell/_scrollAreaStyle';


import { BlogPostCreate, BlogPostEdit, BlogPostList, BlogPostShow } from "./pages/blog-posts";
import {CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from "./pages/categories";

import SettingsBackups from './pages/settings/SettingsBackups';
import SettingsGeneral from './pages/settings/SettingsGeneral';
import SettingsIntegrations from './pages/settings/SettingsIntegrations';
import SupportVideos from './pages/support/SupportVideos';
import SupportHelp from './pages/support/SupportHelp';
import { AppBuilderList, AppBuilderCreate, AppBuilderEdit, AppBuilderShow, AppBuilderSkillEdit, AppsSearch, AppsActive, AppSchedulerList, AppSchedulerCreate, AppSchedulerEdit, AppsChat} from './pages/apps';
import AccountProfile from './pages/account/AccountProfile';
import AccountPreferences from './pages/account/AccountPreferences';
import { DocBuilderContainer } from './components/custom/DocBuilder/DocBuilderContainer';
import { Library } from './components/custom/Library/Library';
import defaultTemplate from './components/custom/DocBuilder/defaultTemplate.json';


function rem(px: number) {
  return `${px / 16}rem`;
}

const LogoutComponent = () => {
  const navigate = useNavigate();
  
  React.useEffect(() => {
    const handleLogout = async () => {
      if (authProvider.logout) {
        const result = await authProvider.logout({ });
        if (result.success) {
          navigate(result.redirectTo || '/login');
        }
      }
    };
    
    handleLogout();
  }, [navigate]);
  
  return null;
};

function App() {
  return (
    <BrowserRouter>
      <Refine
        dataProvider={dataProvider(supabaseClient)}
        liveProvider={liveProvider(supabaseClient)}
        authProvider={authProvider}
        routerProvider={routerBindings}
        resources={[
          { name: "blog_posts", list: "/blog-posts", create: "/blog-posts/create", edit: "/blog-posts/edit/:id", show: "/blog-posts/show/:id", meta: { canDelete: true } },
          { name: "categories", list: "/categories", create: "/categories/create", edit: "/categories/edit/:id", show: "/categories/show/:id", meta: { canDelete: true } },
          { name: "apps", list: "/apps/builder", create: "/apps/builder/create", edit: "/apps/builder/edit/:id", show: "/apps/builder/show/:id", meta: { canDelete: true } },
        ]}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
          useNewQueryKeys: true,
          projectId: "oUgWWA-DIfUwY-EhpCTo",
        }} >
        <TitleProvider>
        <MantineProvider theme={{
          fontFamily: 'Inter, sans-serif',
          headings: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',

            sizes: {
              h1: {
                fontWeight: '100',
                fontSize: rem(36),
                lineHeight: '1.4',
              },
              h2: { fontSize: rem(30), lineHeight: '1.5' },
              h6: { fontWeight: '900' },
            },
          },
        }} >
          <Global styles={{ 
            body: { 
              WebkitFontSmoothing: "auto",
              fontFamily: 'Inter, sans-serif'
            } 
          }} />
          <NotificationsProvider position="top-right">
            <AppShell
              padding={0}
              styles={(theme) => ({
                main: { 
                  backgroundColor: 'transparent',
                  padding: 0,
                  position: "relative",
                  minHeight: "100vh",
                },
              })}
            >
              <Routes>
                <Route path="/logout" element={<LogoutComponent />} />
                <Route path="/login" element={<AuthenticationForm />} />
                <Route path="/register" element={<AuthenticationForm />} />
                <Route path="/reset-password" element={<PasswordResetForm />} />
                
                {/* Authenticated Routes */}
                <Route element={
                  <Authenticated key="authenticated-layout" fallback={<Navigate to="/login" />}>
                    <DoubleNavbar />
                  </Authenticated>
                }>
                  <Route index path="/" element={<Navigate to="/apps/search" replace />} />
                  <Route path="/blog-posts" element={<Outlet />}>
                    <Route index element={<BlogPostList />} />
                    <Route path="create" element={<BlogPostCreate />} />
                    <Route path="edit/:id" element={<BlogPostEdit />} />
                    <Route path="show/:id" element={<BlogPostShow />} />
                  </Route>
                  <Route path="/categories" element={<Outlet />}>
                    <Route index element={<CategoryList />} />
                    <Route path="create" element={<CategoryCreate />} />
                    <Route path="edit/:id" element={<CategoryEdit />} />
                    <Route path="show/:id" element={<CategoryShow />} />
                  </Route>

                  <Route path="/apps" element={<Outlet />}>
                    <Route path="search" element={<AppsSearch />} />
                    <Route path="active" element={<AppsActive />} />
                    <Route path="builder" element={<AppBuilderList />} />
                    <Route path="builder/edit/:id" element={<AppBuilderEdit  />} />
                    <Route path="builder/edit/:id/:tabValue" element={<AppBuilderEdit />} />
                    <Route path="builder/edit/:id/:tabValue/:id2" element={<AppBuilderSkillEdit />} />
                    <Route path="builder/create" element={<AppBuilderCreate />} />
                    <Route path="builder/show/:id" element={<AppBuilderShow />} />
                    <Route path="scheduler" element={<AppSchedulerList />} /> 
                    <Route path="scheduler/create" element={<AppSchedulerCreate />} />
                    <Route path="scheduler/edit/:id" element={<AppSchedulerEdit />} />
                    <Route path="chat" element={<AppsChat />} />

                  </Route>

                  <Route path="/account" element={<Outlet />}>
                    <Route path="profile" element={<AccountProfile />} />
                    <Route path="preferences" element={<AccountPreferences />} />
                  </Route>
                  <Route path="/support" element={<Outlet />}>
                    <Route path="videos" element={<SupportVideos />} />
                    <Route path="help" element={<SupportHelp />} />
                  </Route>
                  <Route path="/settings" element={<Outlet />}>
                    <Route path="backups" element={<SettingsBackups />} />
                    <Route path="general" element={<SettingsGeneral />} />
                    <Route path="integrations" element={<SettingsIntegrations />} />
                  </Route>
                  <Route path="/docs/builder" element={<DocBuilderContainer template={defaultTemplate} />} />
                  <Route path="/docs/builder/:slug" element={<DocBuilderContainer template={defaultTemplate} />} />
                  <Route path="/docs/library" element={<Library />} />
                  <Route path="/docs/library/:slug" element={<DocBuilderContainer template={defaultTemplate} />} />

                </Route>
              </Routes>
            </AppShell>
          </NotificationsProvider>
        </MantineProvider>
        </TitleProvider>
      </Refine>
    </BrowserRouter>
  )
}


export default App;