import { supabaseClient } from "../../../utility/supabaseClient";
import { ContentItem } from "./types";

export const docBuilderService = {
  async fetchDocuments() {
    console.log('Fetching documents...');
    
    const { data, error } = await supabaseClient
      .from('docs')
      .select('*');
    
    if (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
    
    console.log('Raw data from database:', data);
    
    const processedData = data.map(doc => ({
      ...doc,
      name: doc.name || '',
      parent_id: doc.parent_id || null,
      properties: doc.properties || {
        hasChildren: false,
        childProperties: []
      },
      status: doc.status || 0
    }));

    console.log('Processed documents:', processedData);
    return processedData as ContentItem[];
  },

  async fetchCompanyDocuments(companyId: string) {
    try {
      const { data, error } = await supabaseClient
        .from('docs')
        .select('*')
        .eq('company_id', companyId);

      if (error) throw error;
      
      console.log('Fetched documents:', data);
      return data;
    } catch (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
  },

  async fetchDocumentChildren(parentId: string) {
    try {
      // Simply fetch children by parent_id
      const { data, error } = await supabaseClient
        .from('docs')
        .select('*')
        .eq('parent_id', parentId);

      if (error) throw error;
      
      console.log('Fetched children for parent:', parentId, data);
      return data;
    } catch (error) {
      console.error('Error fetching document children:', error);
      throw error;
    }
  },

  async updateDocument(document: ContentItem) {
    if (document.status_id === -1) {
      const { data, error } = await supabaseClient
        .from('docs')
        .update({
          status_id: -1,
        })
        .eq('id', document.id)
        .select()
        .single();
      
      if (error) {
        console.error('Error updating document status:', error);
        throw error;
      }
      return data;
    }

    const updatePayload = {
      name: document.name,
      description: document.description,
      content: document.content,
      properties: document.properties,
      parent_id: document.parent_id,
    };

    console.log('Update payload:', updatePayload);

    const { data, error } = await supabaseClient
      .from('docs')
      .update(updatePayload)
      .eq('id', document.id)
      .select()
      .single();
    
    if (error) {
      console.error('Error updating document:', error);
      throw error;
    }
    return data;
  },

  async createDocument(document: Omit<ContentItem, 'id'>) {
    const { data, error } = await supabaseClient
      .from('docs')
      .insert({
        ...document,
      })
      .single();
    
    if (error) throw error;
    return data;
  },

  async generatePDF(documentId: string) {
    // PDF generation logic here
    const { data, error } = await supabaseClient
      .rpc('generate_doc_pdf', { doc_id: documentId });
    
    if (error) throw error;
    return data;
  },

  async publishDocument(documentId: string) {
    const { data, error } = await supabaseClient
      .from('docs')
      .update({ 
        status: 'published', 
        published_at: new Date().toISOString(),
      })
      .eq('id', documentId)
      .single();
    
    if (error) throw error;
    return data;
  },

  addItem: async (item: Partial<ContentItem>): Promise<ContentItem> => {
    console.log('Adding item:', item);

    if (!item.id || !item.name) {
      throw new Error('id and name are required');
    }

    // Prepare the item for database insertion
    const dbItem = {
      id: item.id,
      name: item.name || '',
      description: item.description || '',
      content: item.content || '',
      // Only include template_parent_id for items under static templates
      ...(item.template_parent_id ? { template_parent_id: item.template_parent_id } : {}),
      // Include parent_id for dynamic items
      ...(item.parent_id ? { parent_id: item.parent_id } : {}),
      properties: item.properties || {},
      status_id: item.status_id,
    };

    console.log('Saving item to database:', dbItem);

    try {
      const { data, error } = await supabaseClient
        .from('docs')
        .insert([dbItem])
        .select()
        .single();

      if (error) {
        console.error('Database error:', error);
        throw error;
      }

      console.log('Added item to database:', data);
      return data as ContentItem;
    } catch (error) {
      console.error('Error adding item:', error);
      throw error;
    }
  }
}; 