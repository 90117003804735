import { useState, useEffect, KeyboardEvent } from 'react';
import { 
  Paper, 
  Container, 
  Tabs, 
  TextInput, 
  Group, 
  Button, 
  Table,
  Select,
  Breadcrumbs,
  Anchor,
  Stack,
  Text,
  Checkbox,
  Title,
  Divider,
  Textarea,
  NumberInput,
  Box,
  createStyles,
  Tooltip
} from '@mantine/core';
import { IconPlus, IconSearch, IconEdit, IconGripVertical, IconChevronRight, IconChevronDown, IconCheck, IconX, IconPlus as IconPlusSmall } from '@tabler/icons-react';
import { ContentItem, PropertyType, DocBuilderProps, ContentItemProperty, isLevel1Item, isLevel2Item, isLevel3Item, isLevelNItem, Level1Item, Level2Item, Level3Item, LevelNItem } from './types';
import TipTapEditor from './TipTapEditor';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Tree } from '@minoru/react-dnd-treeview';
import { useNavigate } from 'react-router-dom';
import { documentService } from './services/documentService';

const PROPERTY_TYPES: PropertyType[] = [
  'text',
  'longtext',
  'number',
  'date',
  'boolean',
  'select'
];

type CorePropertyLabels = {
  singular: string;
  plural: string;
};

const useStyles = createStyles((theme) => ({
  treeRoot: {
    height: '100%',
    width: '100%',
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    '& ul, & li': {
      listStyleType: 'none',
      padding: 0,
      margin: 0
    },
    '& > ul': {  // Target the root level list specifically
      listStyleType: 'none',
      padding: 0,
      margin: 0
    }
  },
  draggingSource: {
    opacity: 0.3,
  },
  dropTarget: {
    backgroundColor: theme.colorScheme === 'dark' 
      ? theme.colors.dark[5] 
      : theme.colors.gray[1],
  }
}));

function assertContentItem(item: any): ContentItem {
  // For Level1Items
  if (!item.parent_id && !item.template_parent_id) {
    return {
      ...item,
      parent_id: null,
      template_parent_id: null,
      template_id: item.template_id || 'root',
      properties: {
        hasChildren: true,
        childLabels: {
          singular: item.properties?.childLabels?.singular || 'Item',
          plural: item.properties?.childLabels?.plural || 'Items'
        },
        childProperties: item.properties?.childProperties || []
      }
    } as Level1Item;
  }

  // For everything else, just force the type
  return item as ContentItem;
}

export function DocBuilder({ 
  items,
  onItemAdd,
  onItemUpdate,
  onGeneratePDF,
  onPublish,
  isLoading = false,
  onItemClick,
  initialSelectedId,
  onLoadChildren
}: DocBuilderProps) {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<string | null>(initialSelectedId || null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingProperties, setIsEditingProperties] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(() => {
    if (initialSelectedId) {
      const initialItem = items.find(item => item.id === initialSelectedId);
      return initialItem?.properties?.hasChildren ? 'list' : 'preview';
    }
    return 'list';
  });

  useEffect(() => {
    if (initialSelectedId && !isEditing && !isEditingProperties) {
      const selectedItem = items.find(item => item.id === initialSelectedId);
      if (selectedItem && activeTab !== 'tree' && activeTab !== 'properties') {
        setActiveTab(selectedItem?.properties?.hasChildren ? 'list' : 'preview');
      }
    }
  }, [initialSelectedId, items, isEditing, isEditingProperties]);

  const [filterText, setFilterText] = useState('');
  const [newPropertyLabel, setNewPropertyLabel] = useState('');
  const [newPropertyType, setNewPropertyType] = useState<PropertyType>('text');
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [hasChildren, setHasChildren] = useState(false);
  const [childLabels, setChildLabels] = useState<CorePropertyLabels>({
    singular: '',
    plural: ''
  });
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    if (initialSelectedId !== undefined) {
      setSelectedId(initialSelectedId);
    }
  }, [initialSelectedId]);

  const getChildren = (parentId: string | null) => {
    return documentService.getChildren(parentId, items);
  };

  const getItem = (id: string | null) => {
    return id ? items.find(item => item.id === id) : null;
  };

  const selected = getItem(selectedId);
  const currentChildren = getChildren(selectedId);

  const getBreadcrumbPath = (id: string | null): ContentItem[] => {
    console.log('Getting breadcrumb path for id:', id);
    const path: ContentItem[] = [];
    let current = getItem(id);
    
    while (current) {
      console.log('Current item in path:', {
        id: current.id,
        name: current.name,
        parent_id: current.parent_id,
        template_parent_id: current.template_parent_id
      });
      path.unshift(current);
      
      if (current?.template_parent_id) {
        // If it's a database item, find its template parent
        const parentItem = items.find(item => item.id === current?.template_parent_id);
        current = parentItem || null;
      } else {
        // If it's a template item, use normal parent_id
        current = current?.parent_id ? items.find(item => item.id === current?.parent_id) : null;
      }
    }
    
    console.log('Final path:', path.map(item => item.name));
    return path;
  };

  const [currentPath, setCurrentPath] = useState<number[]>([]);

  const handleItemClick = (item: ContentItem | undefined) => {
    if (!item) return;
    
    console.log('DocBuilder handleItemClick:', {
      id: item.id,
      name: item.name
    });
    
    setSelectedId(item.id);
    setActiveTab(item.properties?.hasChildren ? 'list' : 'preview');
    
    // If this is a dynamic item with children, load its children
    if (item.template_parent_id && item.properties?.hasChildren) {
      onLoadChildren?.(item.id);
    }
    
    // Call the container's click handler to update URL
    onItemClick(item);
  };

  const handleBreadcrumbClick = (item: ContentItem | null) => {
    if (item) {
      handleItemClick(item);
    } else {
      setSelectedId(null);
      // Create a proper Level1Item for Root
      onItemClick({
        id: 'root',
        name: 'Root',
        parent_id: null,
        template_parent_id: null,
        template_id: 'root',
        content: '',
        properties: {
          hasChildren: true,
          childLabels: {
            singular: 'Item',
            plural: 'Items'
          }
        }
      } as Level1Item);
    }
  };

  const handleAddProperty = () => {
    if (!newPropertyLabel.trim()) return;
    
    const selected = getItem(selectedId);
    if (!selected) return;

    const newProperty = {
      key: newPropertyLabel.toLowerCase().replace(/\s+/g, '_'),
      type: newPropertyType,
      label: newPropertyLabel,
      required: false,
      name: newPropertyLabel
    };

    const updatedItem = {
      ...selected,
      properties: {
        ...selected.properties,
        hasChildren: true,
        childLabels: selected.properties.childLabels || { singular: '', plural: '' },
        childProperties: [
          ...(selected.properties?.childProperties || []),
          newProperty
        ]
      }
    } as ContentItem;

    onItemUpdate(assertContentItem(updatedItem));
    setNewPropertyLabel('');
    setNewPropertyType('text');
  };

  const handleDeleteProperty = (propertyKey: string) => {
    const selected = getItem(selectedId);
    if (!selected) return;

    const childProperties = selected.properties?.childProperties || [];
    
    const updatedItem = {
      ...selected,
      properties: {
        ...selected.properties,
        hasChildren: true,
        childLabels: selected.properties.childLabels || { singular: '', plural: '' },
        childProperties: childProperties.filter(
          (prop: ContentItemProperty) => prop.key !== propertyKey
        )
      }
    } as ContentItem;

    onItemUpdate(assertContentItem(updatedItem));
  };

  const handleGeneratePDF = async () => {
    if (!selectedId || !onGeneratePDF) return;
    await onGeneratePDF(selectedId);
  };

  const handlePublish = async () => {
    if (!selectedId || !onPublish) return;
    await onPublish(selectedId);
  };

  const handleContentUpdate = (newContent: string) => {
    if (!selected) return;
    
    const updatedItem = {
      ...selected,
      content: newContent
    };
    
    onItemUpdate(assertContentItem(updatedItem));
    setIsEditing(false);
  };

  useEffect(() => {
    if (selected) {
      setItemName(selected.name);
      setItemDescription(selected.description || '');
      setHasChildren(selected.properties?.hasChildren || false);
      setChildLabels({
        singular: selected.properties?.childLabels?.singular || '',
        plural: selected.properties?.childLabels?.plural || ''
      });
    }
  }, [selected]);

  const handleCorePropertyUpdate = () => {
    if (!selected) return;
    setIsEditingProperties(true);

    const updatedItem = {
      ...selected,
      name: itemName,
      description: itemDescription,
      properties: {
        ...selected.properties,
        hasChildren
      }
    };

    updateItem(updatedItem);
    setIsEditingProperties(false);
  };

  const handleChildLabelsUpdate = () => {
    if (!selected) return;

    const updatedItem = {
      ...selected,
      properties: {
        ...selected.properties,
        childLabels
      }
    };

    updateItem(updatedItem);
  };

 // console.log('Current items:', items);
 // console.log('Current children:', currentChildren);

  const getInheritedProperties = (item: ContentItem | null): ContentItemProperty[] => {
    if (!item?.parent_id) return [];
    const parent = getItem(item.parent_id);
    return parent?.properties?.childProperties || [];
  };

  const renderCustomProperties = (item: ContentItem) => {
    const inheritedProps = getInheritedProperties(item);
    if (inheritedProps.length === 0) return null;

    return (
      <>
        <Divider />
        <div>
          <Title order={4} mb="sm">Custom Properties</Title>
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Type</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              {inheritedProps.map(prop => (
                <tr key={prop.key}>
                  <td>{prop.label}</td>
                  <td>{prop.type}</td>
                  <td>{prop.required ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  const getCorePropertyLabels = (item: ContentItem | null): {nameLabel: string, descLabel: string} => {
    if (!item?.parent_id) return { nameLabel: 'Name', descLabel: 'Description' };
    
    const parent = getItem(item.parent_id);
    const singular = parent?.properties?.childLabels?.singular || '';
    
    return {
      nameLabel: singular ? `${singular} Name` : 'Name',
      descLabel: singular ? `${singular} Description` : 'Description'
    };
  };

  const propertyLabels = getCorePropertyLabels(selected || null);

  const renderPropertyInput = (prop: ContentItemProperty) => {
    // Create a type for dynamic properties
    type DynamicProperties = {
      [key: string]: any;
    };
    
    const dynamicProps = selected?.properties as DynamicProperties;
    const value = dynamicProps?.[prop.key] || '';
    
    const handleChange = (newValue: any) => {
      if (!selected) return;
      
      const updatedItem = {
        ...selected,
        properties: {
          ...selected.properties,
          [prop.key]: newValue
        }
      } as ContentItem;
      onItemUpdate(assertContentItem(updatedItem));
    };

    switch (prop.type) {
      case 'date':
        return (
          <TextInput
            type="date"
            label={prop.label}
            value={value ? value.split('T')[0] : ''}
            onChange={(e) => handleChange(e.target.value)}
            required={prop.required}
          />
        );
      case 'boolean':
        return (
          <Checkbox
            label={prop.label}
            checked={!!value}
            onChange={(e) => handleChange(e.currentTarget.checked)}
          />
        );
      case 'longtext':
        return (
          <Textarea
            label={prop.label}
            value={value}
            onChange={(e) => handleChange(e.currentTarget.value)}
            required={prop.required}
            minRows={3}
          />
        );
      case 'number':
        return (
          <NumberInput
            label={prop.label}
            value={value}
            onChange={(val) => handleChange(val)}
            required={prop.required}
          />
        );
      default:
        return (
          <TextInput
            label={prop.label}
            value={value}
            onChange={(e) => handleChange(e.currentTarget.value)}
            required={prop.required}
          />
        );
    }
  };

  const handleAddItem = async () => {
    if (!newItemName.trim()) return;
    
    const parent = getItem(selectedId);
    if (!parent) return;

    if (!parent.properties?.hasChildren) {
      console.error('Cannot add items to this section - requires hasChildren=true');
      return;
    }

    const newItem = documentService.createNewItem(parent, newItemName) as ContentItem;

    try {
      await onItemAdd(newItem);
      setNewItemName('');
      setIsAddingItem(false);
    } catch (error) {
      console.error('Error in DocBuilder handleAddItem:', error);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddItem();
    } else if (e.key === 'Escape') {
      setIsAddingItem(false);
      setNewItemName('');
    }
  };

  const { classes } = useStyles();

  const hasActiveChildren = (itemId: string) => {
    return items.some(item => 
      item.parent_id === itemId && (!item.status_id || item.status_id !== -1)
    );
  };

  const treeData = items.map(item => ({
    id: item.id,
    parent: item.parent_id || item.template_parent_id || 0,
    text: item.name,
    data: item,
    droppable: item.properties?.hasChildren
  }));

 // console.log('Tree data:', treeData);

  const updateItem = (item: any) => {
    // For Level1Items, ensure hasChildren is true and childLabels exist
    if (!item.parent_id && !item.template_parent_id) {
      const level1Item: Level1Item = {
        ...item,
        parent_id: null,
        template_parent_id: null,
        template_id: item.template_id || 'root',
        properties: {
          hasChildren: true,
          childLabels: item.properties.childLabels || {
            singular: 'Item',
            plural: 'Items'
          },
          childProperties: item.properties.childProperties || []
        }
      };
      onItemUpdate(assertContentItem(level1Item));
    } else {
      // For all other items, just pass through
      onItemUpdate(assertContentItem(item));
    }
  };

  const handleItemNameUpdate = (node: any, newName: string) => {
    if (!node.data) return;
    
    const updatedItem = {
      ...node.data,
      name: newName
    };
    
    updateItem(updatedItem);
    setIsEditing(false);
    setItemName('');
  };

  const buildNestPath = (item: ContentItem): number[] => {
    const path: number[] = [];
    let currentItem: ContentItem | null = item;

    while (currentItem) {
      let siblings;
      let parentId: string | null = null;
      
      if (currentItem.template_parent_id) {
        siblings = items.filter(i => i.template_parent_id === currentItem?.template_parent_id);
        parentId = currentItem.template_parent_id;
      } else {
        siblings = items.filter(i => i.parent_id === currentItem?.parent_id);
        parentId = currentItem.parent_id;
      }
      
      siblings.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      const position = siblings.findIndex(i => i.id === currentItem?.id) + 1;
      path.unshift(position);

      // Move up the tree
      currentItem = parentId ? items.find(i => i.id === parentId) || null : null;
    }

    return path;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const nestPath = urlParams.get('nest')?.split('/').map(Number) || [];
    
    // If we have a nest path, find the corresponding item
    if (nestPath.length > 0) {
      let currentItems = items.filter(item => item.parent_id === null);
      let targetItem: ContentItem | undefined;
      
      // Follow the nest path to find the target item
      for (let i = 0; i < nestPath.length; i++) {
        const position = nestPath[i] - 1;
        
        if (i === nestPath.length - 1) {
          // For the last position, check both template and database items
          const allItems = [
            ...currentItems,
            ...items.filter(item => 
              item.template_parent_id === targetItem?.id
            )
          ];
          targetItem = allItems[position];
        } else {
          targetItem = currentItems[position];
          currentItems = items.filter(item => 
            item.parent_id === targetItem?.id || 
            item.template_parent_id === targetItem?.id
          );
        }
      }
      
      if (targetItem) {
        setSelectedId(targetItem.id);
        setActiveTab(targetItem.properties?.hasChildren ? 'list' : 'preview');
      }
    }
  }, [items]); // Only re-run if items change

  // Helper function to determine if an item is static (Level 1 or 2)
  const isStaticItem = (item: ContentItem | null | undefined): boolean => {
    if (!item) return true;
    return isLevel1Item(item) || isLevel2Item(item);
  };

  // Helper function to determine if we can add items
  const canAddItems = (item: ContentItem | null | undefined): boolean => {
    if (!item) return false;
    
    // Can never add items to Level 1 or Level 2
    if (isLevel1Item(item) || isLevel2Item(item)) {
      return false;
    }

    // Can only add items if parent has hasChildren=true
    return item.properties?.hasChildren || false;
  };

  const getParentChain = (items: ContentItem[], startId: string): ContentItem[] => {
    const chain: ContentItem[] = [];
    let current: ContentItem | undefined = items.find(item => item.id === startId);
    
    while (current) {
      chain.push(current);
      const parentId = current.parent_id;
      if (!parentId) break;
      current = items.find(item => item.id === parentId);
    }
    
    return chain;
  };

  const handleItemAdd = async (newItem: ContentItem) => {
    if (!newItem.parent_id && !isLevel1Item(newItem)) {
      throw new Error('Invalid item structure');
    }
    await onItemAdd(newItem);
  };

  return (
    <Container size="xl">
      <Paper shadow="sm" p="xl" withBorder>
        {/* Top Bar */}
        <Group position="apart" mb="md">
          <Breadcrumbs>
            <Anchor 
              onClick={() => handleBreadcrumbClick(null)}
              underline={true}
            >
              Root
            </Anchor>
            {getBreadcrumbPath(selectedId).map((item) => (
              <Anchor 
                key={item.id}
                onClick={() => handleBreadcrumbClick(item)}
                underline={true}
              >
                {item.name}
              </Anchor>
            ))}
          </Breadcrumbs>
          <TextInput
            placeholder="Filter items..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            icon={<IconSearch size={16} />}
          />
        </Group>

        {/* Tabs */}
        <Tabs 
          value={activeTab} 
          onTabChange={(value) => {
            // Prevent tab changes if we're editing
            if (!isEditing) {
              setActiveTab(value || 'list');
            }
          }}
        >
          <Tabs.List>
            {(!selected || selected.properties?.hasChildren) && (
              <Tabs.Tab value="list">List</Tabs.Tab>
            )}
            <Tabs.Tab value="preview">Preview</Tabs.Tab>
            <Tabs.Tab value="properties">Properties</Tabs.Tab>
            <Tabs.Tab value="tree">Tree View</Tabs.Tab>
            <Tabs.Tab value="options">Options</Tabs.Tab>
          </Tabs.List>

          {(!selected || selected.properties?.hasChildren) && (
            <Tabs.Panel value="list">
              <Stack mt="md">
                {selected?.properties?.childLabels?.plural && (
                  <Title order={3}>{selected.properties.childLabels.plural}</Title>
                )}
                {currentChildren.length > 0 ? (
                  currentChildren
                    .filter(item => 
                      item?.name?.toLowerCase().includes(filterText.toLowerCase()) || false
                    )
                    .map(item => {
                      // console.log('Rendering list item:', {
                      //   id: item.id,
                      //   name: item.name,
                      //   template_id: item.template_id,
                      //   template_parent_id: item.template_parent_id,
                      //   parent_id: item.parent_id
                      // });
                      return (
                        <Paper
                          key={item.id}
                          p="md"
                          withBorder
                          onClick={() => handleItemClick(item)}
                          sx={(theme) => ({
                            cursor: 'pointer',
                            backgroundColor: !item.template_parent_id && item.parent_id !== undefined
                              ? '#F4F6F6'
                              : undefined,
                            '&:hover': {
                              backgroundColor: !item.template_parent_id && item.parent_id !== undefined
                                ? '#E8EAEB'
                                : theme.colorScheme === 'dark' 
                                  ? theme.colors.dark[6] 
                                  : theme.colors.gray[0]
                            }
                          })}
                        >
                          <Stack spacing={4}>
                            <Text size="md" fw={600}>
                              {item.name}
                            </Text>
                            {item.description && (
                              <Text size="sm" c="dimmed">
                                {item.description}
                              </Text>
                            )}
                          </Stack>
                        </Paper>
                      );
                    })
                ) : (
                  <Text c="dimmed" ta="center" py="xl">
                    No items found. {selectedId && !isStaticItem(selected) ? 'Add a new item to get started.' : 'This folder is empty.'}
                  </Text>
                )}
                {!isStaticItem(selected) && canAddItems(selected) && (
                  isAddingItem ? (
                    <Box mb="md">
                      <Group spacing="xs" noWrap>
                        <TextInput
                          placeholder="Enter item name..."
                          value={newItemName}
                          onChange={(e) => setNewItemName(e.target.value)}
                          sx={{ width: '300px' }}
                          onKeyDown={handleKeyPress}
                          autoFocus
                        />
                        <Group spacing={4}>
                          <Tooltip label="Save" color="blue.1" withArrow closeDelay={0}>
                            <Button 
                              variant="subtle" 
                              size="xs"
                              p={4}
                              onClick={() => handleAddItem()}
                            >
                              <IconCheck size={16} />
                            </Button>
                          </Tooltip>
                          <Tooltip label="Cancel" color="blue.1" withArrow closeDelay={0}>
                            <Button 
                              variant="subtle" 
                              color="red" 
                              size="xs"
                              p={4}
                              onClick={() => {
                                setIsAddingItem(false);
                                setNewItemName('');
                              }}
                            >
                              <IconX size={16} />
                            </Button>
                          </Tooltip>
                        </Group>
                      </Group>
                    </Box>
                  ) : (
                    <Button 
                      fullWidth
                      leftIcon={<IconPlus size={16} />}
                      variant="light"
                      onClick={() => setIsAddingItem(true)}
                    >
                      Add {selected?.properties?.childLabels?.singular || 'Item'}
                    </Button>
                  )
                )}
              </Stack>
            </Tabs.Panel>
          )}

          <Tabs.Panel value="preview">
            <Group position="right" mb="md">
              <Button
                variant="subtle"
                leftIcon={<IconEdit size={16} />}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Cancel' : 'Edit'}
              </Button>
            </Group>
            
            {isEditing ? (
              <TipTapEditor
                content={selected?.content || ''}
                onUpdate={handleContentUpdate}
              />
            ) : (
              <div 
                dangerouslySetInnerHTML={{ __html: selected?.content || '' }}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value="properties">
            {selected && (
              <Stack spacing="md" pb="xl">
                <div style={{ marginTop: "1.5rem" }}>
                  <Stack spacing="xs">
                    <TextInput
                      label={propertyLabels.nameLabel}
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value);
                        setIsEditingProperties(true);
                      }}
                      onBlur={() => {
                        handleCorePropertyUpdate();
                        setIsEditingProperties(false);
                      }}
                    />
                    <TextInput
                      label={propertyLabels.descLabel}
                      value={itemDescription}
                      onChange={(e) => {
                        setItemDescription(e.target.value);
                        setIsEditingProperties(true);
                      }}
                      onBlur={() => {
                        handleCorePropertyUpdate();
                        setIsEditingProperties(false);
                      }}
                    />
                  </Stack>
                </div>

                {selected.parent_id && getInheritedProperties(selected).length > 0 && (
                  <>
                    <Divider />
                    <div>
                      <Title order={4} mb="sm">Custom Properties</Title>
                      <Stack spacing="xs">
                        {getInheritedProperties(selected).map(prop => (
                          <div key={prop.key}>
                            {renderPropertyInput(prop)}
                          </div>
                        ))}
                      </Stack>
                    </div>
                  </>
                )}

                <Divider />
                <div>
                  <Checkbox
                    label="Has Children"
                    checked={hasChildren}
                    onChange={(e) => {
                      const newValue = e.currentTarget.checked;
                      setHasChildren(newValue);
                      const updatedItem = {
                        ...selected,
                        properties: {
                          ...selected.properties,
                          hasChildren: newValue
                        }
                      };
                      onItemUpdate(assertContentItem(updatedItem));
                    }}
                  />
                </div>

                {hasChildren && (
                  <>
                    <div>
                      <Title order={4} mb="sm">Child Labels</Title>
                      <Group grow>
                        <TextInput
                          label="Singular (e.g., Policy)"
                          value={childLabels.singular}
                          onChange={(e) => setChildLabels({
                            ...childLabels,
                            singular: e.target.value
                          })}
                          onBlur={handleChildLabelsUpdate}
                        />
                        <TextInput
                          label="Plural (e.g., Policies)"
                          value={childLabels.plural}
                          onChange={(e) => setChildLabels({
                            ...childLabels,
                            plural: e.target.value
                          })}
                          onBlur={handleChildLabelsUpdate}
                        />
                      </Group>
                    </div>

                    <div>
                      <Title order={4} mb="sm">Child Properties</Title>
                      <Table>
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selected.properties?.childProperties || []).map(prop => (
                            <tr key={prop.key}>
                              <td>{prop.label}</td>
                              <td>{prop.type}</td>
                              <td>{prop.required ? 'Yes' : 'No'}</td>
                              <td>
                                <Button
                                  color="red"
                                  variant="subtle"
                                  onClick={() => handleDeleteProperty(prop.key)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <TextInput
                                value={newPropertyLabel}
                                onChange={(e) => setNewPropertyLabel(e.target.value)}
                                placeholder="New property label"
                              />
                            </td>
                            <td>
                              <Select
                                value={newPropertyType}
                                onChange={(value) => setNewPropertyType(value as PropertyType)}
                                data={PROPERTY_TYPES.map(type => ({
                                  value: type,
                                  label: type.charAt(0).toUpperCase() + type.slice(1)
                                }))}
                              />
                            </td>
                            <td></td>
                            <td>
                              <Button
                                rightIcon={<IconPlus size={16} />}
                                onClick={handleAddProperty}
                              >
                                Add
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
              </Stack>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="tree">
            <Box pt="md">
              <DndProvider backend={HTML5Backend}>
                <Group position="right" mb="md" spacing="sm">
                  <Button
                    variant="light"
                    color="orange"
                    onClick={() => setShowDeleted(!showDeleted)}
                  >
                    {showDeleted ? 'Hide Deleted' : 'View Deleted'}
                  </Button>
                </Group>
                
                {isAddingItem && (
                  <Box mb="md">
                    <Group spacing="xs" noWrap>
                      <TextInput
                        placeholder="Enter item name..."
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                        sx={{ width: '300px' }}
                        onKeyDown={handleKeyPress}
                        autoFocus
                      />
                      <Group spacing={4}>
                        <Tooltip label="Save" color="blue.1" withArrow closeDelay={0}>
                          <Button 
                            variant="subtle" 
                            size="xs"
                            p={4}
                            onClick={() => handleAddItem()}
                          >
                            <IconCheck size={16} />
                          </Button>
                        </Tooltip>
                        <Tooltip label="Cancel" color="blue.1" withArrow closeDelay={0}>
                          <Button 
                            variant="subtle" 
                            color="red" 
                            size="xs"
                            p={4}
                            onClick={() => {
                              setIsAddingItem(false);
                              setNewItemName('');
                            }}
                          >
                            <IconX size={16} />
                          </Button>
                        </Tooltip>
                      </Group>
                    </Group>
                  </Box>
                )}

                <Tree
                  tree={treeData}
                  rootId={0}
                  render={(node, { depth, isOpen, onToggle }) => (
                    <Group 
                      spacing="xs"
                      pl={depth * 20}
                      py="xs"
                      onClick={(e) => {
                        if (isEditing) {
                          e.stopPropagation();
                        }
                      }}
                      sx={(theme) => ({
                        cursor: 'pointer',
                        backgroundColor: node.data?.status_id === -1 
                          ? theme.colors.orange[1] 
                          : !node.data?.template_parent_id && node.data?.parent_id !== undefined
                            ? '#F4F6F6'  // Grey background for template items
                            : 'transparent',
                        display: (!showDeleted && node.data?.status_id === -1) ? 'none' : 'flex',
                        '&:hover': {
                          backgroundColor: node.data?.status_id === -1 
                            ? theme.colors.orange[2]
                            : !node.data?.template_parent_id && node.data?.parent_id !== undefined
                              ? '#E8EAEB'  // Slightly darker on hover for template items
                              : theme.colorScheme === 'dark' 
                                ? theme.colors.dark[6] 
                                : theme.colors.gray[0]
                        }
                      })}
                    >
                      <IconGripVertical 
                        size={16} 
                        style={{ cursor: 'grab' }}
                        color="gray"
                      />
                      {node.droppable && (
                        <Box onClick={onToggle} style={{ cursor: 'pointer' }}>
                          {isOpen ? (
                            <IconChevronDown size={16} />
                          ) : (
                            <IconChevronRight size={16} />
                          )}
                        </Box>
                      )}
                      
                      {selectedId === node.id && isEditing ? (
                        <Group spacing="xs" noWrap>
                          <TextInput
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            size="xs"
                            sx={{ width: '300px' }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleItemNameUpdate(node, itemName);
                              } else if (e.key === 'Escape') {
                                setIsEditing(false);
                                setItemName(''); // Reset the item name
                              }
                            }}
                            autoFocus
                          />
                          <Group spacing={4}>
                            <Tooltip label="Save" color="blue.1" withArrow closeDelay={0}>
                              <Button 
                                variant="subtle" 
                                size="xs"
                                p={4}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleItemNameUpdate(node, itemName);
                                }}
                              >
                                <IconCheck size={16} />
                              </Button>
                            </Tooltip>
                            <Tooltip label="Cancel" color="blue.1" withArrow closeDelay={0}>
                              <Button 
                                variant="subtle" 
                                color="red" 
                                size="xs"
                                p={4}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsEditing(false);
                                  setItemName(''); // Reset the item name
                                }}
                              >
                                <IconX size={16} />
                              </Button>
                            </Tooltip>
                          </Group>
                        </Group>
                      ) : (
                        <Group spacing="xs" onClick={(e) => e.stopPropagation()}>
                          <Text onClick={(e) => {
                            e.stopPropagation();
                            if (!isEditing) {
                              handleItemClick(node.data);
                            }
                          }}>{node.text}</Text>
                          {(!node.data?.template_id && node.data?.template_parent_id) && (
                            <Group spacing={4}>
                              <Tooltip label="Edit" color="blue.1" withArrow closeDelay={0}>
                                <Button
                                  variant="subtle"
                                  size="xs"
                                  p={4}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedId(node.id as string);
                                    setItemName(node.text as string);
                                    setIsEditing(true);
                                  }}
                                >
                                  <IconEdit size={16} />
                                </Button>
                              </Tooltip>
                              {!isStaticItem(node.data) && (
                                <Tooltip label="Add Child" color="blue.1" withArrow closeDelay={0}>
                                  <Button
                                    variant="subtle"
                                    size="xs"
                                    p={4}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedId(node.id as string);
                                      setIsAddingItem(true);
                                    }}
                                  >
                                    <IconPlusSmall size={16} />
                                  </Button>
                                </Tooltip>
                              )}
                            </Group>
                          )}
                        </Group>
                      )}
                    </Group>
                  )}
                  onDrop={(newTree) => {
                    newTree.forEach(node => {
                      if (!node.data || !node.id) return;

                      const updatedItem = {
                        ...node.data,
                        parent_id: node.parent === 0 ? null : (node.parent as string)
                      };

                      onItemUpdate(assertContentItem(updatedItem));
                    });
                  }}
                  classes={{
                    root: classes.treeRoot,
                    draggingSource: classes.draggingSource,
                    dropTarget: classes.dropTarget
                  }}
                />
              </DndProvider>
            </Box>
          </Tabs.Panel>

          <Tabs.Panel value="options">
            <Stack spacing="md" pt="md">
              {onGeneratePDF && (
                <Button 
                  onClick={handleGeneratePDF}
                  fullWidth
                  variant="light"
                  fw="normal"
                >
                  Generate PDF
                </Button>
              )}
              {onPublish && (
                <Button 
                  onClick={handlePublish}
                  fullWidth
                  variant="light"
                  fw="normal"
                >
                  Publish
                </Button>
              )}
              {selected && (
                <>
                  <Divider />
                  <Box>
                    <Title order={4} mb="sm" color="red">Danger Zone</Title>
                    {hasActiveChildren(selected.id) ? (
                      <Text color="red" size="sm">
                        Cannot delete "{selected.name}". You must first delete or move all child items.
                      </Text>
                    ) : (
                      <Stack spacing="xs">
                        <Text size="sm">
                          To delete "{selected.name}", type the name below to confirm.
                        </Text>
                        <TextInput
                          placeholder={selected.name}
                          value={deleteConfirmation}
                          onChange={(e) => setDeleteConfirmation(e.target.value)}
                          mb="xs"
                        />
                        <Button 
                          color="red"
                          fullWidth
                          disabled={deleteConfirmation !== selected.name}
                          onClick={async () => {
                            if (deleteConfirmation === selected.name && selected) {
                              try {
                                const updatedItem = {
                                  ...selected,
                                  status_id: -1,
                                  updated_at: new Date().toISOString(),
                                  company_id: selected.company_id
                                };
                                
                                await onItemUpdate(assertContentItem(updatedItem));
                                setDeleteConfirmation('');
                                handleBreadcrumbClick(null);
                              } catch (error) {
                                console.error('Error deleting item:', error);
                              }
                            }
                          }}
                        >
                          Delete {selected.name}
                        </Button>
                      </Stack>
                    )}
                  </Box>
                </>
              )}
            </Stack>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Container>
  );
} 