import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { DocBuilder } from './DocBuilder';
import { docBuilderService } from './docBuilderService';
import { ContentItem, isLevel1Item, isLevel2Item, isLevel3Item, isLevelNItem, Level1Item } from './types';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { templateService } from './templateService';

export interface DocBuilderContainerProps {
  template?: any; // The template JSON
  onItemClick?: (item: ContentItem) => void;
}

export function DocBuilderContainer({ 
  template,
  onItemClick: containerOnItemClick
}: DocBuilderContainerProps) {
  const [items, setItems] = useState<ContentItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // Hardcode a UUID for testing
  const companyId = "550e8400-e29b-41d4-a716-446655440000";

  const getSection = () => {
    return location.pathname.startsWith('/docs/library') ? 'library' : 'builder';
  };

  useEffect(() => {
    const init = async () => {
      await initializeTemplate();
    };
    init();
  }, []);

  // Get the nest parameter from URL
  const getNestFromUrl = (): number[] => {
    const nestParam = searchParams.get('nest');
    return nestParam ? nestParam.split('/').map(Number) : [];
  };

  // Helper function to get item by ID
  const getItemById = (id: string): ContentItem | null => {
    return items.find(item => item.id === id) || null;
  };

  // Get the display order from the template
  const getDisplayOrder = (): string[] => {
    // Convert template to tree items to get the initial order
    const templateItems = convertTemplateToTreeItems(template);
    return templateItems
      .filter(i => isLevel1Item(i))
      .map(i => i.id);
  };

  // Custom sort function using template order
  const sortLevel1Items = (a: ContentItem, b: ContentItem) => {
    const displayOrder = getDisplayOrder();
    console.log('Display order:', {
      order: displayOrder,
      itemA: { id: a.id, name: a.name, pos: displayOrder.indexOf(a.id) },
      itemB: { id: b.id, name: b.name, pos: displayOrder.indexOf(b.id) }
    });

    const indexA = displayOrder.indexOf(a.id);
    const indexB = displayOrder.indexOf(b.id);

    // If both items are in the display order, sort by their position
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one item is in the display order, put it first
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // For items not in the display order, sort alphabetically
    return a.name.localeCompare(b.name);
  };

  // Get item from nest path - updated for all levels
  const getItemFromNest = (nestPath: number[]): ContentItem | null => {
    console.log('Getting item from nest path:', nestPath);
    if (nestPath.length === 0) return null;

    // Get all Level 1 items in display order
    let currentItems = items
      .filter(i => isLevel1Item(i))
      .sort(sortLevel1Items);

    // Get first level item
    let currentItem = currentItems[nestPath[0] - 1];
    if (!currentItem) return null;

    // Handle all subsequent levels
    for (let i = 1; i < nestPath.length; i++) {
      const position = nestPath[i] - 1;
      
      // Get children of current item
      currentItems = items.filter(item => {
        if (isLevel2Item(currentItem!)) {
          // For Level 2 parents, look for template_parent_id matches
          return item.template_parent_id === currentItem!.id;
        } else if (item.parent_id === currentItem!.id) {
          // For all other levels, look for direct parent_id matches
          return true;
        }
        return false;
      }).sort((a, b) => (a.name || '').localeCompare(b.name || ''));

      console.log(`Level ${i + 1} items under ${currentItem.name}:`, {
        parentId: currentItem.id,
        items: currentItems.map((item, idx) => ({
          id: item.id,
          name: item.name,
          parent_id: item.parent_id,
          template_parent_id: item.template_parent_id,
          position: idx + 1
        }))
      });

      currentItem = currentItems[position];
      if (!currentItem) {
        console.log(`No item found at position ${position + 1} for level ${i + 1}`);
        return null;
      }
    }

    return currentItem;
  };

  // Build nest path - updated for all levels
  const buildNestPath = (item: ContentItem): number[] => {
    const path: number[] = [];
    let currentItem: ContentItem | null = item;
    
    // Build path from bottom up
    while (currentItem) {
      let siblings: ContentItem[];
      let parentItem: ContentItem | null = null;

      if (isLevel1Item(currentItem)) {
        // For Level 1 items
        siblings = items
          .filter(i => isLevel1Item(i))
          .sort(sortLevel1Items);
      } else {
        // For all other levels
        if (currentItem.template_parent_id) {
          // Item is under a Level 2 template
          parentItem = getItemById(currentItem.template_parent_id);
          siblings = items
            .filter(i => i.template_parent_id === currentItem!.template_parent_id)
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        } else {
          // Item is under another dynamic item
          parentItem = currentItem.parent_id ? getItemById(currentItem.parent_id) : null;
          siblings = items
            .filter(i => i.parent_id === currentItem!.parent_id)
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        }
      }

      const position = siblings.findIndex(i => i.id === currentItem!.id) + 1;
      console.log('Found position:', {
        itemName: currentItem.name,
        position,
        siblings: siblings.map(i => i.name)
      });

      if (position > 0) {
        path.unshift(position);
      }

      // Move up the tree
      currentItem = parentItem;
    }

    console.log('Built nest path:', {
      itemName: item.name,
      path
    });
    return path;
  };

  // Create URL-friendly name
  const createSlug = (name: string): string => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      const section = getSection();
      const currentPath = location.pathname;
      const nestPath = getNestFromUrl();
      const itemId = currentPath.split('/').pop();

      console.log('\n=== URL Navigation Effect ===', {
        currentPath,
        section,
        nestPath,
        itemId,
        isRoot: currentPath === `/docs/${section}`
      });
      
      // Skip if we're at the root
      if (currentPath === `/docs/${section}`) {
        return;
      }

      // First try to find item by ID
      let targetItem = itemId ? getItemById(itemId) : null;
      console.log('Found by ID:', targetItem?.name);
      
      // If no item found by ID and we have a nest path, try that
      if (!targetItem && nestPath.length > 0) {
        targetItem = getItemFromNest(nestPath);
        console.log('Found by nest path:', targetItem?.name);
      }

      if (targetItem) {
        // Calculate the correct nest path for this item
        const correctNestPath = buildNestPath(targetItem);
        console.log('Path comparison:', {
          current: nestPath,
          correct: correctNestPath,
          needsUpdate: JSON.stringify(nestPath) !== JSON.stringify(correctNestPath)
        });

        // Update URL if the nest path is incorrect
        if (JSON.stringify(nestPath) !== JSON.stringify(correctNestPath)) {
          const newUrl = `/docs/${section}/${targetItem.id}?nest=${correctNestPath.join('/')}`;
          console.log('Updating URL to:', newUrl);
          navigate(newUrl, { replace: true });
        }
        
        // Always notify parent component
        if (containerOnItemClick) {
          containerOnItemClick(targetItem);
        }
      } else {
        console.log('No item found, resetting to root');
        navigate(`/docs/${section}`, { replace: true });
      }
    }
  }, [isLoading, items, location.pathname]);

  // Update handleItemClick to handle navigation
  const handleItemClick = async (item: ContentItem) => {
    console.log('\n=== handleItemClick ===');
    console.log('Clicked item:', {
      id: item.id,
      name: item.name,
      parent_id: item.parent_id,
      template_parent_id: item.template_parent_id,
      isLevel1: isLevel1Item(item),
      isLevel2: isLevel2Item(item)
    });

    if (!item.id) return;

    const section = getSection();
    const nestPath = buildNestPath(item);
    
    // Use ID for navigation to ensure uniqueness
    const urlIdentifier = item.id;
    const newUrl = `/docs/${section}/${urlIdentifier}?nest=${nestPath.join('/')}`;
    
    console.log('Navigation details:', {
      section,
      nestPath,
      urlIdentifier,
      newUrl
    });

    // First notify parent component
    if (containerOnItemClick) {
      containerOnItemClick(item);
    }

    // Then update URL - use replace to prevent back button issues
    navigate(newUrl, { replace: true });
  };

  const loadDocuments = async () => {
    try {
      // Load template items without sorting
      const templateItems = convertTemplateToTreeItems(template);
      
      // Load all documents
      const data = await docBuilderService.fetchCompanyDocuments(companyId);
      
      // Keep all documents that are either:
      // 1. Children of templates (template_parent_id)
      // 2. Children of other documents (parent_id)
      const documentItems = data.filter(doc => {
        return doc.template_parent_id || doc.parent_id;
      });
      
      // Combine items and sort Level 1 items
      const allItems = [...templateItems, ...documentItems];
      const sortedItems = allItems.filter(isLevel1Item)
        .sort(sortLevel1Items)
        .concat(allItems.filter(item => !isLevel1Item(item)));

      console.log('Final combined items:', sortedItems.map(i => ({
        name: i.name,
        position: allItems.filter(isLevel1Item).indexOf(i) + 1,
        parent_id: i.parent_id,
        template_parent_id: i.template_parent_id
      })));
      
      setItems(sortedItems);
    } catch (error) {
      console.error('Error loading documents:', error);
    }
  };

  // Add a function to load children when clicking on a dynamic item
  const loadDynamicItemChildren = async (parentId: string) => {
    try {
      const children = await docBuilderService.fetchDocumentChildren(parentId);
      // Add these children to the items state
      setItems(prev => {
        // Remove any existing children for this parent to avoid duplicates
        const withoutChildren = prev.filter(item => item.parent_id !== parentId);
        return [...withoutChildren, ...children];
      });
    } catch (error) {
      console.error('Error loading children:', error);
    }
  };

  // Helper to find items in the template structure
  const findTemplateItem = (id: string) => {
    for (const section of template) {
      for (const subsection of section.subsections) {
        if (subsection.id === id) return subsection;
      }
    }
    return null;
  };

  // Convert template to tree items without database interaction
  const convertTemplateToTreeItems = (template: any): ContentItem[] => {
    const items: ContentItem[] = [];
    
    // Get the main sections with their properties
    const sections = templateService.getRootTemplate();
    items.push(...sections);
    
    // For each section, add its subsection templates
    sections.forEach((section) => {
      // Get subsection templates
      const subsectionTypes = ['processes', 'reporting', 'assets', 'legal'];
      
      subsectionTypes.forEach(type => {
        const subsectionTemplate = templateService.getSubsectionTemplate(type);
        if (subsectionTemplate) {
          const subsectionItem = templateService.createSubsection(subsectionTemplate, section.id);
          items.push(subsectionItem);
        }
      });
    });
    
    console.log('Generated template items:', items);
    return items;
  };

  const handleItemAdd = async (newItem: Partial<ContentItem>): Promise<ContentItem> => {
    // Ensure all required fields are present
    if (!newItem.id || !newItem.name) {
      throw new Error('Missing required fields');
    }

    const completeItem: ContentItem = {
      ...newItem,
      content: newItem.content || '',
      properties: newItem.properties || {},
      template_parent_id: newItem.template_parent_id || null,
      parent_id: newItem.parent_id || null,
    } as ContentItem;

    const response = await docBuilderService.addItem(completeItem);
    setItems(prev => [...prev, response]);
    return response;
  };

  const handleItemUpdate = async (updatedItem: ContentItem) => {
    try {
      // Check if item is from template
      const templateItems = convertTemplateToTreeItems(template);
      const isTemplateItem = templateItems.some(item => item.id === updatedItem.id);
      
      if (isTemplateItem) {
        throw new Error('Template items cannot be modified');
      }
      
      console.log('Updating item:', updatedItem);
      await docBuilderService.updateDocument(updatedItem);
      
      setItems(prev => prev.map(item => 
        item.id === updatedItem.id ? updatedItem : item
      ));
      
    } catch (error) {
      console.error('Error updating document:', error);
      showNotification({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to update document',
        color: 'red'
      });
      throw error;
    }
  };

  const handleGeneratePDF = async (itemId: string) => {
    try {
      await docBuilderService.generatePDF(itemId);
      // Comment out success notification
      /*showNotification({
        title: 'Success',
        message: 'PDF generated successfully',
        color: 'green'
      });*/
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to generate PDF',
        color: 'red'
      });
    }
  };

  const handlePublish = async (itemId: string) => {
    try {
      await docBuilderService.publishDocument(itemId);
      // Comment out success notification
      /*showNotification({
        title: 'Success',
        message: 'Document published successfully',
        color: 'green'
      });*/
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to publish document',
        color: 'red'
      });
    }
  };

  const initializeTemplate = async () => {
    try {
      // Just load the documents
      await loadDocuments();
    } catch (error) {
      console.error('Error initializing template:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to initialize template',
        color: 'red'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add type guard
  const isLevel1Item = (item: ContentItem): item is Level1Item => {
    return item.parent_id === null;
  };

  const loadRootItems = async () => {
    try {
      const rootItems = templateService.getRootTemplate();
      setItems(prevItems => {
        const newItems = [...prevItems];
        rootItems.forEach(item => {
          if (!newItems.find(i => i.id === item.id)) {
            newItems.push(item);
          }
        });
        return newItems;
      });
    } catch (error) {
      console.error('Error loading root items:', error);
    }
  };

  return (
    <DocBuilder
      items={items}
      onItemAdd={handleItemAdd}
      onItemUpdate={handleItemUpdate}
      onGeneratePDF={handleGeneratePDF}
      onPublish={handlePublish}
      isLoading={isLoading}
      onItemClick={handleItemClick}
      initialSelectedId={getItemFromNest(getNestFromUrl())?.id || null}
      onLoadChildren={loadDynamicItemChildren}
    />
  );
} 